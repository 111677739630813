import {
  cleanContentfulData,
  getAvailableLocales,
  formatLocales,
  isLocalePermitted,
} from '../../lib/utils';
import { getContentFromQuery, getGlobalsFromBrand, getLocales } from '../../lib/contentful';
import { Layout } from '../../components';
import { getComponent } from '../../lib/ComponentFactory';
import { getLocalizationProps } from '../../lib/LanguageProvider';
import safeStringify from 'safe-json-stringify';
import PageContext from '../../components/PageContext';
import { getHomepageData, getFreeformHomepageData } from '../../lib/homepageUtils';
import { formatContentByContentType } from '../../lib/propResolver';

/**
 * Page from a brand given a locale
 * @param {object} [props] Component props
 * @param {object} [props.homeData] data retrieved from contentful
 * @param {object} [props.globalData] global data
 * @param {string} [props.locale] locale used in page
 * @param {object} [props.localesData] locales data
 * @returns {JSX.Element} brand page from locale
 */
export default function Locale({ homeData, globalData, locale, localesData }) {
  return (
    <PageContext.Provider value={{ templateEntryId: homeData?.sys?.id }}>
      <Layout
        title={homeData.fields.headline}
        brandSlug={`/${homeData.fields.brand.fields.slug}`}
        globalData={globalData}
        seoData={homeData.fields.seoData}
        locale={locale}
        localesData={localesData}>
        {getComponent(homeData.sys.contentType.sys.id, {
          fields: homeData.fields,
        })}
      </Layout>
    </PageContext.Provider>
  );
}

/**
 *  method from next js to retrieve data
 *
 * @private
 */
export async function getStaticProps({ params, preview = false }) {
  let contentType;
  const brand = process.env.BRAND;
  const brandData = await getContentFromQuery({
    content_type: 'brand',
    'fields.slug': brand,
  });

  const overrideHomePageId = brandData[0]?.fields?.overrideHomepage;

  let pageData = overrideHomePageId
    ? await getFreeformHomepageData(params.locale, overrideHomePageId, preview)
    : await getHomepageData(brand, params.locale, preview);

  // if the overridehomepage is not available in this locale fallback to homepage
  if (overrideHomePageId && pageData?.[0]?.fields?.availability !== 'active') {
    pageData = await getHomepageData(brand, params.locale, preview);
  } else {
    contentType = 'freeFormTemplate';
  }

  const globalData = await getGlobalsFromBrand(brand, params.locale, preview);
  const localization = getLocalizationProps(params.locale);
  const availableLocales = getAvailableLocales(brandData);
  const locales = await getLocales(availableLocales);
  const instagramClientToken =
    brand === 'go-solutions'
      ? process.env.GO_SOLUTION_INSTAGRAM_ACCESS_TOKEN
      : process.env.NOW_FRESH_INSTAGRAM_ACCESS_TOKEN;

  const parsedPageData = JSON.parse(safeStringify(pageData[0]));
  const localesData = formatLocales(locales);
  if (contentType === 'freeFormTemplate') {
    formatContentByContentType(contentType, parsedPageData);
  }

  return {
    props: cleanContentfulData({
      homeData: parsedPageData,
      locale: params.locale,
      localesData,
      localeMap: {},
      key: params.locale,
      brand,
      localization,
      globalData,
      instagramClientToken,
    }),
  };
}

/**
 *  method from next js to set paths
 *
 * @private
 */
export async function getStaticPaths() {
  const brand = process.env.BRAND;
  const brandData = await getContentFromQuery({
    content_type: 'brand',
    'fields.slug': brand,
  });

  const availableLocales = getAvailableLocales(brandData);
  const filteredLocales = availableLocales.filter((locale) => {
    return isLocalePermitted(locale);
  });
  const mappedLocalesAndBrands = filteredLocales.map((locale) => {
    return {
      params: {
        locale,
      },
    };
  });
  return {
    paths: mappedLocalesAndBrands,
    fallback: false,
  };
}
